.display-2, .display-4, .menu-image-simple .col h3, .menu-image-sidebar p,  {
  text-shadow: 2px 2px 1px rgba(55,55,55,.7);
  font-weight: 500;
}

.testimonials-section, .website-footer {
  text-shadow: 2px 2px 1px rgba(55,55,55,.7);
}

@media (min-width: 1200px) {
  .home .content-wrap {
    max-width: 900px;
  }
}

.navbar-dark .navbar-nav .nav-link {
  font-size: 1.1rem;
  text-transform: uppercase;
  font-weight: bold;
}
#menu-primary .page-item-300 a {
  border: 1px solid white;
  margin-left: 2rem;
}
/* GLOBAL */

h1 {
  color: #47AA42;
  font-weight: bold;
  text-transform: uppercase;
}

body a {
  color: #3c903c;
}

body.media {
  display: block; }

body, .bg-white, .text-body {
  color: #0B1B0B; }

a, button, svg {
  -webkit-transition: color 0.2s linear 0s, background-color 0.2s linear 0s, border-color 0.2s linear 0s;
  -o-transition: color 0.2s linear 0s, background-color 0.2s linear 0s, border-color 0.2s linear 0s;
  transition: color 0.2s linear 0s, background-color 0.2s linear 0s, border-color 0.2s linear 0s; }

.btn-wcag {
  position: absolute;
  top: 3rem;
  left: 1rem;
  color: white;
  border: 1px solid white;
z-index: 3; }

.btn-skip-content {
  position: fixed !important;
  top: 0;
  left: 0;
  z-index: 1030;
  color: white;
  border: 1px solid white;
  padding: .375rem .75rem; }

.btn-skip-nav {
  position: absolute !important;
  top: 0;
  left: 0;
  z-index: 1030;
  color: white;
  border: 1px solid white;
  padding: .375rem .75rem; }

a:not(.btn) {
  text-decoration: underline; }

.content a:not(.btn):not(.submenu a) {
  font-weight: bold;
  text-decoration: underline !important; }

.content, .section {
  line-height: 1.6; }

#content {
  position: relative; }

.content-highlight {
  position: absolute;
  -webkit-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
  top: 0;
  left: 0;
  background: #fff8a3;
  z-index: -1; }

.content img {
  max-width: 100%;
  height: auto; }

body.no-scrolling {
  overflow: hidden; }

.menu-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

.embed-responsive {
  margin-bottom: 1rem; }

.text-white a {
  color: white; }

/* SAGE DEFAULT */
.comment-list ol {
  list-style: none; }

.alignnone {
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;
  height: auto; }

.aligncenter {
  display: block;
  margin: 1rem auto;
  height: auto; }

.alignleft, .alignright {
  margin-bottom: 1rem;
  height: auto; }

@media (min-width: 576px) {
  .alignleft {
    float: left;
    margin-right: 1rem; }
  .alignright {
    float: right;
    margin-left: 1rem; } }

body#tinymce {
  margin: 12px !important; }

/* FOCUS */
a:focus {
  outline-style: dotted;
  outline-width: 1px; }

input:focus, select:focus, textarea:focus, [tabindex]:not([tabindex='-1']):focus {
  outline-style: dotted;
  outline-width: 2px; }

.bg-primary a:focus, .bg-secondary a:focus, .bg-tetriary a:focus, .white-outline a:focus {
  outline-color: white !important; }

.bg-primary a:not(.btn), .bg-secondary a:not(.btn), .white-outline a:not(.btn) {
  color: white; }

.bg-primary a .svg-inline--fa, .bg-secondary a .svg-inline--fa, .white-outline a .svg-inline--fa {
  border-bottom: 1px solid white;
  padding-bottom: 2px; }

.bg-primary, .bg-secondary {
  color: white; }

/* NAVBAR */
.navbar-brand img {
  width: auto;
  max-height: 80px; }

  .navbar-brand {
    padding-top: 0;
    padding-bottom: 0;
  }

.navbar .navbar-collapse {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  z-index: 99; }

.dropdown-item.active, .dropdown-item:active, .navbar-nav a:focus, .navbar-nav a:hover {
  background: transparent; }

.navbar:not(.highlight-active) .navbar-nav .nav-link.highlighted {
  background-color: white; }

.nav-link:focus, .nav-link:hover {
  text-decoration: underline; }

.navbar-nav a {
  text-decoration: none !important; }

.navbar-dark .navbar-nav .nav-link, .navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .active > .nav-link, .navbar-dark .navbar-nav .nav-link.active, .navbar-dark .navbar-nav .nav-link.show, .navbar-dark .navbar-nav .show > .nav-link {
  color: white; }

.navbar-dark .navbar-toggler {
  color: white;
  border-color: white; }

.navbar-dark .navbar-toggler span {
  background: white; }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-dark .navbar-nav.sm-collapsible .nav-link .sub-arrow, .navbar-dark .navbar-nav.sm-collapsible .sub-arrow {
  border-color: white; }

.navbar-dark .dropdown-menu {
  border-color: white; }

.navbar-light .navbar-nav .nav-link, .navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover, .navbar-light .dropdown-item, .navbar-light .navbar-nav .active > .nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show > .nav-link {
  color: gray; }

.navbar-light .navbar-toggler {
  color: gray;
  border-color: gray; }

.navbar-light .navbar-toggler span {
  background: gray; }

.navbar-light .navbar-nav.sm-collapsible .nav-link .sub-arrow, .navbar-light .navbar-nav.sm-collapsible .sub-arrow {
  border-color: gray; }

.navbar-light .dropdown-menu {
  border-color: gray; }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(128, 128, 128, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.dropdown-menu {
  border-radius: 0; }

.navbar-toggler {
  position: relative;
  height: 40px;
  width: 56px; }

.navbar-toggler span {
  border: none;
  height: 2px;
  width: 24px;
  position: absolute;
  top: 10px;
  left: 50%;
  -webkit-transition: all .35s ease;
  -o-transition: all .35s ease;
  transition: all .35s ease;
  cursor: pointer;
  -webkit-transform: translateX(-50%);
       -o-transform: translateX(-50%);
          transform: translateX(-50%); }

.navbar-toggler span:nth-of-type(2) {
  top: 18px; }

.navbar-toggler span:nth-of-type(3) {
  top: 26px; }

.navbar-toggler[aria-expanded="true"] .top {
  -webkit-transform: translateY(8px) translateX(-50%) rotate(45deg);
  -o-transform: translateY(8px) translateX(-50%) rotate(45deg);
     transform: translateY(8px) translateX(-50%) rotate(45deg); }

.navbar-toggler[aria-expanded="true"] .middle {
  opacity: 0; }

.navbar-toggler[aria-expanded="true"] .bottom {
  -webkit-transform: translateY(-8px) translateX(-50%) rotate(-45deg);
  -o-transform: translateY(-8px) translateX(-50%) rotate(-45deg);
     transform: translateY(-8px) translateX(-50%) rotate(-45deg); }

.navbar-highlight {
  position: absolute;
  -webkit-transition: .5s;
  -o-transition: .5s;
  transition: .5s;
  background: #e4335c;
  z-index: 1; }

@media (max-width: 991px) {
  .fixed-top {
    z-index: 1031;
    max-height: 100vh;
    overflow: auto; } }

/* CF7 & SEARCH FORM */
.search-form label {
  font-weight: normal; }

.wpcf7-form, .error404 .searchform {
  max-width: 550px;
}

.wpcf7-text, .wpcf7-textarea, .wpcf7-select {
  width: 100%; }

/* SUBMENU */
.flc-submenu ul {
  list-style: none;
  padding: 0;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }

.submenu-column ul {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-line-pack: center;
      align-content: center; }

.flc-submenu li {
  width: 50%;
  padding: 3px; }

.flc-submenu a {
  color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  height: 100%;
  border-style: solid;
  border-width: 1px;
  text-decoration: none !important;
  outline: 0;
  padding: 5px; }

.flc-submenu a:focus, .flc-submenu a:hover {
  background: white !important; }

@media (max-width: 767px) {
  .flc-submenu li {
    width: 100%; } }

/* BUTTONS */
.btn {
  text-decoration: none !important;
  border-radius: 0; }

.btn:focus {
  box-shadow: unset;
  outline-color: transparent; }

.btn .svg-inline--fa {
  border-bottom: none !important; }

/* MOBILE CTA */
.mobile-cta a {
  width: 50%;
  padding: 0.5rem 0;
  border: 1px solid; }

.mobile-cta a:focus {
  outline: inherit; }

.website-footer {
  background-image: url('../images/greenlawn.jpg');
  background-size: cover;
  background-position: center;
}

.website-footer a {
  color:white;
}

.logo img{
  width: 100px;
}

/* BLOG */
.entry-summary {
  margin-bottom: 4rem;
  padding-bottom: 6rem;
  position: relative;
  border-bottom: 1px dashed; }

.entry-summary .attachment-thumbnail, .entry-content .attachment-thumbnail {
  margin: 0 auto 1rem;
  display: block; }

.entry-summary .sharing {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  margin-bottom: 2rem; }

@media (min-width: 992px) {
  .entry-summary .attachment-thumbnail, .entry-content .attachment-thumbnail {
    float: left;
    margin-right: 1rem; } }

/* SECTIONS */

.section, .content-wrap {
  padding-top: 3rem;
  padding-bottom: 3rem; }

.section header {
  text-align: center;
  padding-bottom: 2rem; }

.section header > h2 {
  margin: 0;
  padding: 0; }

/* LATEST POSTS */
.section-latest-posts {
  border-bottom: 3px solid; }

.section-latest-posts ul {
  list-style: none;
  padding: 0; }

@media (max-width: 767px) {
  .section-latest-posts li {
    margin-bottom: 3rem; }
  .section-latest-posts li:last-of-type {
    margin-bottom: 0; }
  .section-latest-posts .btn {
    float: right; } }

/* SOCIALS */
.socials a, .sharing a {
  margin: 3px; }

a .fa-stack .svg-inline--fa:last-of-type {
  border-bottom: transparent !important; }

.sharing-text {
  padding-bottom: 5px;
  margin: 5px; }

.contrastHigh a:focus .color-facebook, .contrastHigh a:hover .color-facebook {
  color: #3b5998; }

.contrastHigh a:focus .color-twitter, .contrastHigh a:hover .color-twitter {
  color: #4099ff; }

.contrastHigh a:focus .color-google-plus, .contrastHigh a:hover .color-google-plus {
  color: #d34836; }

.contrastHigh a:focus .color-linkedin, .contrastHigh a:hover .color-linkedin {
  color: #0077B5; }

.contrastHigh a:focus .color-youtube, .contrastHigh a:hover .color-youtube {
  color: #e52d27; }

.contrastHigh a:focus .color-pinterest, .contrastHigh a:hover .color-pinterest {
  color: #BD081C; }

.contrastHigh a:focus .color-instagram, .contrastHigh a:hover .color-instagram {
  color: #CD486B; }

.contrastHigh a:focus .color-yelp, .contrastHigh a:hover .color-yelp {
  color: #d32323; }

/* IMAGE HEADER */
.header-image .header-img, .header-image .attachment-full {
  max-width: 100%;
  width: 100%;
  height: auto;
position: absolute;
top: 0;
left: 0;
z-index: -1;}

.header-image {
  padding-top: 95px;
  height: 22.5vw;
  position: relative;
  overflow: hidden; }
.home .header-image {
  min-height: 40vw;
}
.header-image > .center {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
       -o-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 0;
  left: 0; }

@media (max-width: 991px) {
  .header-image a, .header-image .breadcrumb-item.active, .breadcrumb-item + .breadcrumb-item::before {
    color: black !important; }
  .header-image a:focus {
    outline-color: black !important; }
  .header-image > .center {
    position: static;
    -webkit-transform: translateY(0%);
         -o-transform: translateY(0%);
            transform: translateY(0%); } }

/* BREADCRUMBS */
.breadcrumb {
  background: transparent;
  padding-left: 0;
  padding-right: 0; }

.header-image .breadcrumb-item.active {
  color: white; }

.breadcrumb-item + .breadcrumb-item::before {
  color: white; }

.header-image ol {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important; }

/* TEST ROWS */
.row-logo img {
  max-height: 50px;
  width: auto;
  padding: 0 1.5rem;
  max-width: 100%;
  margin: 1.5rem 0; }

.row-newsletter label {
  margin-bottom: 0;
  font-size: 1.3rem; }

/* HEADER ROW */
.header-row img {
  max-width: 100%; }

/* BEFORE AFTER GALLERY */
.flc-before-after {
  list-style: none;
  margin: 0;
  padding: 0; }

.flc-before-after li {
  margin-bottom: 1rem; }

.cross2-slider:focus, .cross2-slider:hover {
  border-color: transparent !important; }

/* OVERLAYS */
.overlay-solid, .overlay-grid {
  position: relative; }

.overlay-solid::before {
  display: block;
  content: " ";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: absolute; }

.overlay-gradient {
  background: -webkit-linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  background: -o-linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)); }

.overlay-grid::after {
  display: block;
  content: " ";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  background-image: -webkit-repeating-linear-gradient(135deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) 1px, transparent 2px, transparent 2px, rgba(0, 0, 0, 0.3) 3px);
  background-image: -o-repeating-linear-gradient(135deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) 1px, transparent 2px, transparent 2px, rgba(0, 0, 0, 0.3) 3px);
  background-image: -webkit-repeating-linear-gradient(315deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) 1px, transparent 2px, transparent 2px, rgba(0, 0, 0, 0.3) 3px);
  background-image: -o-repeating-linear-gradient(315deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) 1px, transparent 2px, transparent 2px, rgba(0, 0, 0, 0.3) 3px);
  background-image: repeating-linear-gradient(135deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) 1px, transparent 2px, transparent 2px, rgba(0, 0, 0, 0.3) 3px);
  background-size: 4px 4px; }

/* FAQ */
.fa-chevron-down {
  -webkit-transform: rotate(0deg);
       -o-transform: rotate(0deg);
          transform: rotate(0deg);
  -webkit-transition: -webkit-transform 0.3s ease 0s !important;
  transition: -webkit-transform 0.3s ease 0s !important;
  -o-transition: -o-transform 0.3s ease 0s !important;
  transition: transform 0.3s ease 0s !important;
  transition: transform 0.3s ease 0s, -webkit-transform 0.3s ease 0s, -o-transform 0.3s ease 0s !important; }

.collapsed .fa-chevron-down {
  -webkit-transform: rotate(-90deg);
       -o-transform: rotate(-90deg);
          transform: rotate(-90deg); }

/* CAROUSEL TEXT ONLY */
.carousel-text-only .bx-wrapper {
  padding-right: 4rem;
  padding-left: 4rem;
  text-align: center; }

/* CAROUSEL ROW LOGOS */
.carousel-row-logos {
  padding-right: 4rem;
  padding-left: 4rem; }

.carousel-row-logos .bx-wrapper {
  text-align: center;
  padding-bottom: 2rem;
  margin: 0 auto; }

.carousel-row-logos .bx-prev {
  left: -2rem; }

.carousel-row-logos .bx-next {
  right: -2rem; }

.carousel-row-logos img {
  max-height: 150px;
  margin: 0 auto; }

.carousel-row-logos li {
  float: left;
  list-style: outside none none;
  position: relative; }

.carousel-row-logos .bx-controls-auto {
  position: absolute;
  right: 0;
  left: 0;
  text-align: right;
  padding-right: 2rem; }

/* CAROUSEL IMAGE WITH TEXT */
.carousel-image-with-text .carousel-text {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 50%;
  padding-right: 4rem;
  padding-left: 4rem;
  text-align: center;
  padding-right: 20%; }

.carousel-image-with-text .position-relative > img {
  width: 100%; }

.carousel-image-with-text .bx-wrapper .bx-pager, .carousel-image-with-text .bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-controls-auto {
  position: absolute;
  bottom: 2rem; }

.carousel-image-with-text, .carousel-image-with-text .bx-wrapper, .carousel-image-with-text img {
  height: 22.65vw;
  overflow: hidden; }

/* CAROUSEL BACKGROUND IMAGE */
.carousel-background-image .bx-viewport li {
  width: 100%;
  background-size: cover; }

.carousel-background, .carousel-background-image .bx-viewport li {
  height: 100vh; }

.carousel-background-image .bx-controls-auto-item {
  position: absolute;
  right: 1rem;
  bottom: 1rem; }

/* MOUSE ANIMATION */
.carousel-background-image::after {
  font-family: "FontAwesome";
  content: "\f078";
  position: absolute;
  bottom: 10px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -o-transform: translateX(-50%);
     transform: translateX(-50%);
  font-size: 2rem;
  color: #fff;
  width: 55px;
  line-height: 55px;
  text-align: center;
  -webkit-animation: bounce 2s infinite;
  -o-animation: bounce 2s infinite;
     animation: bounce 2s infinite;
  -webkit-animation-iteration-count: infinite;
       -o-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-iteration-count: 2;
       -o-animation-iteration-count: 2;
          animation-iteration-count: 2; }

@-webkit-keyframes bounce {
  0% {
    bottom: 10px; }
  25% {
    bottom: 15px; }
  50% {
    bottom: 10px; }
  75% {
    bottom: 20px; }
  to {
    bottom: 10px; } }

@-o-keyframes bounce {
  0% {
    bottom: 10px; }
  25% {
    bottom: 15px; }
  50% {
    bottom: 10px; }
  75% {
    bottom: 20px; }
  to {
    bottom: 10px; } }

@keyframes bounce {
  0% {
    bottom: 10px; }
  25% {
    bottom: 15px; }
  50% {
    bottom: 10px; }
  75% {
    bottom: 20px; }
  to {
    bottom: 10px; } }

/* flc-amenities */
.flc-amenities {
  list-style: none;
  padding: 0;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }

.flc-amenities li {
  width: 25%;
  padding: 5px;
  text-align: center; }

@media (max-width: 991px) {
  .flc-amenities li {
    width: 33.3333%; } }

@media (max-width: 767px) {
  .flc-amenities li {
    width: 50%; } }

@media (max-width: 575px) {
  .flc-amenities li {
    width: 100%; } }

/* OPENING HOURS */
.section-opening-hours h3 {
  font-size: 1.5rem; }

.section-opening-hours .col {
  padding: 15px; }

.section-opening-hours .current-day {
  background: #F8F9FA;
  border-bottom: 1px solid;
  font-weight: bold; }

/* EXPANDABLE REVIEWS */
.morelink {
  margin-top: 1rem;
  display: block; }

#testimonials-modal .modal-content {
  max-height: 70vh; }

#testimonials-modal .modal-body {
  overflow: auto; }

#testimonials-modal .modal-footer {
  padding: 0; }

.testimonials-blockquote cite {
  font-size: 1.3rem; }

.testimonials-stars .fa-star {
  margin-right: 3px;
  color: #ffca2e;
  font-size: 1.3rem; }

.testimonials-average {
  font-size: 2rem; }

.testimonials-average small {
  font-size: 50%; }

.testimonials-section {
background-color: #163716;
}

.form {
background-image: url('../images/greengrass.jpg');
background-position: center;
background-size: cover;
}

.footer1 {
  background-image: url('../images/mover.jpg');
  background-position: right;
  background-size: contain;
  background-repeat: no-repeat;
color: black;
font-size: 20px;
}

.footer1 a {
  color: black;
}

.footer2 {
  font-size: 15px;
}

.cta .fa-phone, .cta a {
  font-size: 25px;
  color: #47AA42;
}

.cta {
  background-color: #10250e;
  color: white;
}

/* TEAM */
.section-team img {
  max-width: 100%; }

.section-team figcaption {
  margin: 1rem 0; }

/* flc-coupons */
.flc-coupons {
  padding: 0;
  list-style: none; }

.flc-coupons li {
  border: 3px dashed white;
  padding: 1rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  outline: 5px solid;
  margin: 5px 5px 2rem 5px;
  position: relative; }

.flc-coupons li p:last-of-type {
  margin-bottom: 0; }

.flc-coupons a {
  position: absolute;
  right: -3px;
  top: -3px;
  border-radius: 0; }

@media print {
  .flc-coupons li {
    border-color: black; }
  .flc-coupons a {
    display: none; } }

/* MENU IMAGE */

.menu-image-simple {
  margin-top: -6rem;
background: white;
z-index: 1;
position: relative;
padding: 8px;
}

.page-header h1 {
  text-decoration: none;
}

.menu-image-simple .col {
  -ms-flex: 0 0 25%;
  -webkit-box-flex: 0;
  flex: 0 0 25%;
  max-width: 25%;
  position: relative;
  width: 100%;
  min-height: 1px;
  padding: 8px;
  a {
    padding-top: 5rem;
    padding-bottom: 5rem;
    text-align: center;
    text-decoration: none;
    color: white;
    z-index: 1;
    text-shadow: 0px 0px 2px #000000;
    background-size: cover;
  }
a::before {
  display: block;
  content: " ";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: absolute;
transition: background 0.5s;
}
h3 {
    z-index: 1;
    position: relative;
    &::before {
    border-top: 1px solid white;
    content: "";
    margin: 0 auto;
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    right: 0;
    bottom: 0;
    width: 150px;
    z-index: -1;
}
&::after {
    border-bottom: 1px solid white;
    content: "";
    margin: 0 auto;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    right: 0;
    bottom: -10px;
    width: 150px;
    z-index: -1;
}
}
  a:hover::before, a:focus::before {
    background: rgba(0, 0, 0, 0.2);
    transition: background 0.5s;
  }
}

.menu-image-sidebar {
    a {
    padding-top: 2rem;
    padding-bottom: 2rem;
    text-align: center;
    text-decoration: none;
    color: white;
    z-index: 1;
    text-shadow: 0px 0px 2px #000000;
    background-size: cover;
  }
a::before {
  display: block;
  content: " ";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: absolute;
transition: background 0.5s;
}
p {
    z-index: 1;
    position: relative;
    &::before {
    border-top: 1px solid white;
    content: "";
    margin: 0 auto;
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    right: 0;
    bottom: 0;
    width: 150px;
    z-index: -1;
}
&::after {
    border-bottom: 1px solid white;
    content: "";
    margin: 0 auto;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    right: 0;
    bottom: -10px;
    width: 150px;
    z-index: -1;
}
}
  a:hover::before, a:focus::before {
    background: rgba(0, 0, 0, 0.2);
    transition: background 0.5s;
    color: white!important;
  }
}

.menu-image-simple .col:nth-of-type(1) {
  -ms-flex: 0 0 40%;
  flex: 0 0 40%;
  max-width: 40%;
}

.menu-image-simple .col:nth-of-type(2) {
  -ms-flex: 0 0 30%;
  flex: 0 0 30%;
  max-width: 30%;
}

.menu-image-simple .col:nth-of-type(3) {
  -ms-flex: 0 0 30%;
  flex: 0 0 30%;
  max-width: 30%;
}

.menu-image-hover ul {
  padding: 0;
  margin: 0;
  list-style: none; }

.menu-image-hover .menu-item {
  min-height: 400px;
  position: relative; }

.menu-image-hover .menu-item ul {
  display: none; }

.menu-image-hover .menu-item.menu-active ul, .touchevents .menu-image-hover .menu-item ul {
  display: block; }

@media (max-width: 767px) {
  .menu-image-hover .menu-item ul {
    display: block !important;
    height: auto !important; } }

.menu-image-hover h3 {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  text-align: center;
  background: white;
  margin: 0;
  padding: 0.5rem 0; }

.menu-image-simple ul {
  padding: 0;
  margin: 0;
  list-style: none; }

.menu-image-hover-collapse .card {
  min-height: 0;
  background: white !important; }

.menu-image-hover-collapse .card h3 {
  position: static;
  display: block;
  width: 100%;
  padding: 0; }

.menu-image-hover-collapse .card h3 a {
  position: relative;
  background: transparent;
  border: none;
  width: 100%;
  text-align: left;
  display: block;
  padding: 1rem; }

.menu-image-hover-collapse .card h3 .sub-arrow {
  position: absolute;
  z-index: 2;
  top: 50%;
  -webkit-transform: translateY(-50%);
       -o-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: .25rem;
  padding: 0;
  width: 2em;
  height: 1.4em;
  font-size: 1.25rem;
  line-height: 1.2em;
  text-align: center; }

.menu-image-hover-collapse .card h3 .collapsed .sub-arrow::before {
  content: "+"; }

.menu-image-hover-collapse .card h3 .sub-arrow::before {
  content: "-"; }

@media (max-width: 767px) {
  .menu-image-hover-collapse .align-items-center {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: start !important; } }

/* MENU IMAGE BRICKS */
.menu-image-bricks ul {
  z-index: 2;
  border-left: 0.5rem solid white;
  border-right: 0.5rem solid white;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0; }

.menu-image-bricks a {
  border: solid 0.5rem white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  padding: 8rem 1rem;
  background-color: transparent;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s; }

.menu-image-bricks img {
  position: absolute;
  display: block;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  opacity: 0;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s; }

.menu-image-bricks a:hover, .menu-image-bricks a:focus {
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s; }

.menu-image-bricks a:hover img, .menu-image-bricks a:focus img {
  opacity: 1; }

.menu-image-bricks .box {
  position: unset;
  height: auto;
  padding: 0; }

/* FINACIAL TABLE */
.table-javascript caption {
  caption-side: top;
  font-weight: bold;
  text-align: center;
  color: black; }

.table-javascript th:nth-of-type(2n), .table-javascript td:nth-of-type(2n+1) {
  border-bottom: 1px dashed !important; }

.table-javascript tr, .table-javascript thead th {
  border-bottom: 1px dashed !important; }

.table-javascript th, .table-javascript td {
  display: table-cell;
  line-height: 1.2;
  padding: 20px 40px;
  text-align: center;
  border-top: none !important; }

.table-javascript th {
  width: 33.3333%; }

@media (max-width: 768px) {
  .table-javascript th, .table-javascript td {
    width: 100%;
    display: block;
    border: transparent !important;
    text-align: left;
    padding: 0.5rem; }
  .table-javascript th:nth-of-type(2n), .table-javascript td:nth-of-type(2n+1) {
    border: none !important; }
  .table-javascript thead {
    display: none; }
  .table-javascript tr {
    padding: 1rem 0; } }

/* BACKGROUND VIDEO */
#video {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover; }

#btn-video {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  border: none;
  background: transparent;
  z-index: 1; }

.backgroundvideo {
  width: 100%;
  height: 100vh;
  overflow: hidden; }

.menu-container {
  position: absolute;
  z-index: 3;
  right: 0;
  left: 0;
}

/*# sourceMappingURL=main.css.map */

@media (max-width: 1199px) {
  .navbar-brand img {
      max-height: 55px;
  }
  .navbar-dark .navbar-nav .nav-link {
      font-size: 1rem;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
      padding-right: .4rem;
      padding-left: .4rem;
  }
  #navbarNavDropdown .btn {
    margin-left: .5rem !important;
  }
  .menu-container {
    padding: 0;
  }
  .menu-image-simple {
    margin-top: 0;
  }
  .display-2 {
    font-size: 4.5rem;
  }
}

@media (max-width: 991px) {
  .header-image {
    padding-top: 70px;
  }
  #navbarNavDropdown .btn {
    display: block !important;
    margin: .5rem !important;
  }
  .menu-image-simple .col a {
    padding-top: 3rem;
    padding-bottom: 2rem;
  }
}

@media (max-width: 767px) {
  .display-2 {
    font-size: 3.5rem;
  }
  .menu-image-simple .col, .menu-image-simple .col:nth-of-type(2), .menu-image-simple .col:nth-of-type(3) {
      -ms-flex: 0 0 50%;
      -webkit-box-flex: 0;
      flex: 0 0 50%;
      max-width: 50%;
  }
  .menu-image-simple .col:nth-of-type(1) {
      -ms-flex: 0 0 100%;
      -webkit-box-flex: 0;
      flex: 0 0 100%;
      max-width: 100%;
  }
  .website-footer {
    text-align: center!important;
  }
  body {
    margin-bottom: 42px;
  }
}

@media (max-width: 575px) {
  .btn-wcag {
    top: 5rem;
  }
  .content-wrap {
    padding-top: 1rem!important;
    padding-bottom: 1rem!important;
  }
  .display-2 {
    display: none;
  }
  .menu-image-simple .col, .menu-image-simple .col:nth-of-type(2), .menu-image-simple .col:nth-of-type(3) {
    -ms-flex: 0 0 100%;
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .menu-image-simple .col a {
    padding-top: 2rem;
    padding-bottom: 1rem;
  }
  .menu-image-simple .col a .h3, .menu-image-simple .col a h3 {
    font-size: 1.2rem;
  }
  .menu-image-simple .col {
    padding: 1px;
  }
}
